import { Controller } from '@hotwired/stimulus'

export default class extends Controller {
  connect() {
    if (window.scrollY < 100) {
      this.element.classList.add('is-active')

      this.listener = () => {
        if (window.scrollY >= 100) {
          this.element.classList.remove('is-active')
          window.removeEventListener('scroll', this.listener)
          this.listener = null
        }
      }

      window.addEventListener('scroll', this.listener)
    }
  }

  disconnect() {
    if (this.listener) {
      window.removeEventListener('scroll', this.listener)
    }
    this.listener = null
  }
}
