import { Controller } from '@hotwired/stimulus'
import Swiper from 'swiper'

export default class extends Controller {
  static targets = ['mainSlider', 'subSlider']

  connect() {
    this.subSlider = new Swiper(this.subSliderTarget, {
      slidesPerView: 'auto',
      spaceBetween: 8,
      allowTouchMove: false,
      direction: 'vertical',
    })

    this.mainSlider = new Swiper(this.mainSliderTarget, {
      loop: true,
      effect: 'fade',
      fadeEffect: {
        crossFade: true
      },
      thumbs: {
        swiper: this.subSlider
      }
    })
  }

  disconnect() {
    this.mainSlider.destroy()
    this.subSlider.destroy()
    this.mainSlider = null
    this.subSlider = null
  }
}
