import { Controller } from '@hotwired/stimulus'

export default class extends Controller {
  static targets = ['item']

  connect() {
    this.observer = new IntersectionObserver(
      entries => {
        entries.forEach(entry => {
          if (entry.isIntersecting) {
            entry.target.classList.add('is-active')
          }
        })
      },
      {
        rootMargin: '0px 0px -200px 0px',
      }
    )
    this.itemTargets.forEach(target => {
      this.observer.observe(target)
    })
  }

  disconnect() {
    this.observer.disconnect()
    this.observer = null
  }
}
