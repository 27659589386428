import { Controller } from '@hotwired/stimulus'

export default class extends Controller {
  static targets = ['section', 'anchor']

  connect() {
    this.observer = new IntersectionObserver(
      entries => {
        entries.forEach(entry => {
          if (entry.isIntersecting) {
            const id = entry.target.id
            this.anchorTargets.forEach(anchor => {
              if (anchor.hash === `#${id}`) {
                anchor.classList.add('is-active')
              } else {
                anchor.classList.remove('is-active')
              }
            })
          }
        })
      },
      {
        rootMargin: '-50% 0px',
      }
    )
    this.sectionTargets.forEach(target => {
      this.observer.observe(target)
    })
  }

  disconnect() {
    this.observer.disconnect()
    this.observer = null
  }

}
