import { Controller } from '@hotwired/stimulus'
import Swiper from 'swiper'

export default class extends Controller {
  static targets = ['mainSlider', 'subSlider']

  connect() {
    this.sliderId = this.element.dataset.sliderId

    this.subSlider = new Swiper(this.subSliderTarget, {
      slidesPerView: 3,
      spaceBetween: 4,
      allowTouchMove: false,
    })

    this.mainSlider = new Swiper(this.mainSliderTarget, {
      loop: true,
      effect: 'fade',
      fadeEffect: {
        crossFade: true
      },
      thumbs: {
        swiper: this.subSlider
      }
    })
  }

  disconnect() {
    this.mainSlider.destroy()
    this.subSlider.destroy()
    this.mainSlider = null
    this.subSlider = null
  }
}
